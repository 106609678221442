var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"grading"}},[_c('section',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('SearchBar',{staticClass:"mb-3",attrs:{"placeholder":'Search course',"value":_vm.search},on:{"update:value":function($event){_vm.search=$event},"clear":_vm.clearSearch,"search":_vm.onSearch}}),_c('FilterMenu',{staticClass:"mb-3",attrs:{"initialFilter":_vm.filter},on:{"resetFilters":() => {
          _vm.minDate = ''
          _vm.maxDate = ''
          _vm.resetFilters()
        },"applyFilters":() => { _vm.page = 1, _vm.updateQuery() }}},[_c('v-sheet',{staticClass:"overflow-y-auto scroller",attrs:{"max-height":"300"}},[_c('FormSelectObject',{attrs:{"label":'STATUS',"items":_vm.status,"value":_vm.filter.status},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status", $event)}}})],1)],1)],1),_c('section',{staticClass:"mb-1 d-flex flex-row justify-space-between"},[(_vm.filter.created_from || _vm.filter.created_to)?_c('FormLabel',{staticClass:"mb-3",attrs:{"label":`Courses conducted ${_vm.filter.created_from ? `from ${_vm.$dateFormat.mmDDyy(_vm.filter.created_from)}` : ''} ${_vm.filter.created_to ? `to ${_vm.$dateFormat.mmDDyy(_vm.filter.created_to)}` : ''}`}}):_vm._e(),(_vm.totalCount !== 0)?_c('FormLabel',{staticClass:"ml-auto",attrs:{"label":`${_vm.totalCount} result/s`}}):_vm._e()],1),_c('v-data-table',{staticClass:"text--center custom-border poppins f14 mb-3",attrs:{"headers":_vm.grading_tbl,"items":_vm.grading,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
    },"page":_vm.page,"server-items-length":_vm.totalCount},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"pagination":(e) => {
        _vm.page = e.page
        _vm.paginate = String(e.itemsPerPage),
        _vm.updateQuery()
    }},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [(item)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.status)+"ED ")]):_vm._e()]}},{key:"item.created_at",fn:function({ item }){return [(item)?_c('span',[_vm._v(" "+_vm._s(_vm.$dateFormat.mmDDyy(item.created_at))+" ")]):_vm._e()]}},{key:"item.start",fn:function({ item }){return [(item)?_c('span',[_vm._v(_vm._s(item.available_until?.start_date ? _vm.$dateFormat.mmDDyy(item.available_until.start_date) : ''))]):_vm._e()]}},{key:"item.end",fn:function({ item }){return [(item)?_c('span',[_vm._v(_vm._s(item.available_until?.end_date ? _vm.$dateFormat.mmDDyy(item.available_until.end_date) : ''))]):_vm._e()]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{staticClass:"primary--text text-capitalize",attrs:{"text":"","small":"","to":{ name: `${_vm.user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Course`, params: { id: item.id, course_name: item.name }, query: { search: '', page: 1, paginate: 10 } }}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" View ")],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }