<template>
  <section id="grading">
    <section class="d-flex flex-wrap align-center justify-space-between">
      <SearchBar 
        :placeholder="'Search course'" 
        :value.sync="search"  
        @clear="clearSearch"
        @search="onSearch"
        class="mb-3"/>

      <FilterMenu
          class="mb-3"
          :initialFilter="filter"
          @resetFilters="() => {
            minDate = ''
            maxDate = ''
            resetFilters()
          }"
          @applyFilters="() => { page = 1, updateQuery() }"
        >
        <v-sheet max-height="300" class="overflow-y-auto scroller">
          <FormSelectObject
            :label="'STATUS'"
            :items="status"
            :value.sync="filter.status"
          />
        </v-sheet>
      </FilterMenu>
    </section>

    <section class="mb-1 d-flex flex-row justify-space-between">                
        <FormLabel v-if="filter.created_from || filter.created_to" :label="`Courses conducted ${filter.created_from ? `from ${$dateFormat.mmDDyy(filter.created_from)}` : ''} ${filter.created_to ? `to ${$dateFormat.mmDDyy(filter.created_to)}` : ''}`" class="mb-3"/>
        <FormLabel class="ml-auto" :label="`${totalCount} result/s`" v-if="totalCount !== 0"/>
    </section>

    <v-data-table
      :headers="grading_tbl"
      :items="grading"
      class="text--center custom-border poppins f14 mb-3"
      :loading="loading"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
          'items-per-page-options': itemsPerPageOptions
      }"
      :page="page"
      :server-items-length="totalCount"
      @pagination="(e) => {
          page = e.page
          paginate = String(e.itemsPerPage),
          updateQuery()
      }">
      
      <template v-slot:item.status="{ item }">
          <span v-if="item" class="text-capitalize"> {{ item.status }}ED </span>
      </template>

      <template v-slot:item.created_at="{ item }">
          <span v-if="item"> {{ $dateFormat.mmDDyy(item.created_at) }} </span>
      </template>
      
      <template v-slot:item.start="{ item }">
          <span v-if="item">{{ item.available_until?.start_date ? $dateFormat.mmDDyy(item.available_until.start_date) : '' }}</span>
      </template>

      <template v-slot:item.end="{ item }">
          <span v-if="item">{{ item.available_until?.end_date ? $dateFormat.mmDDyy(item.available_until.end_date) : '' }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          text
          small
          class="primary--text text-capitalize"
          :to="{ name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Course`, params: { id: item.id, course_name: item.name }, query: { search: '', page: 1, paginate: 10 } }"
        >
          <v-icon left small>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>

  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { grading_tbl } from '@/constants/tblheaders/grading'
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';

export default {
  mixins: [searchAndPaginateMixin],
  methods: {
    ...mapMutations(['alertMutation']),
    ...mapActions(
        "instructor", 
        { 
          getInstructorExportAssessmentResponses: "getCoursesGrading", 
        }
    ),

    ...mapActions(
        "admin", 
        { 
          getAdminExportAssessmentResponses: "getCoursesGrading", 
        }
    ),

    ...mapMutations('instructor', {
      getInstructorGradingMutation : 'gradingMutation'
    }),

    ...mapMutations('admin', {
      getAdminGradingMutation : 'gradingMutation'
    }),

    ...mapMutations('instructor', ['gradingMutation']),

    resetFilters(){
      this.filter = {
        status: '',
        created_from: ``,
        created_to: ``
      }

      this.page = 1
      this.updateQuery()
    },

    getData(){
      if(!this.loading) {
        if(this.user.role === 'INSTRUCTOR') {
          this.loading = true
          this.getInstructorExportAssessmentResponses({ paginate: Number(this.paginate), page: this.page, search: this.search, ...this.filter }).then((res) => {
            this.pageCount = res.last_page
            this.paginate = String(res.per_page)
            this.page = res.current_page
            this.totalCount = res.total
            this.loading = false
          }).catch(() => {
            this.loading = false
            this.alertMutation({
                show: true,
                text: 'Something went wrong',
                type: "error"
            })
          })
        }
        if(this.user.role === 'ADMIN') {
          this.loading = true
          this.getAdminExportAssessmentResponses({ paginate: Number(this.paginate), page: this.page, search: this.search, ...this.filter }).then((res) => {
            this.pageCount = res.last_page
            this.paginate = String(res.per_page)
            this.page = res.current_page
            this.totalCount = res.total
            this.loading = false
          }).catch(() => {
            this.loading = false
            this.alertMutation({
                show: true,
                text: 'Something went wrong',
                type: "error"
            })
          })
        }
      }
    }
  },
  computed: {
    ...mapState('instructor', {
        instructorGrading: (state) => state.grading
    }),
    
    ...mapState('admin', {
        adminGrading: (state) => state.grading
    }),
    
    ...mapState({
      user: (state) => state.user
    }),

    grading(){
      if (!this.user) return [];

      const gradingMap = {
          INSTRUCTOR: this.instructorGrading,
          ADMIN: this.adminGrading,
      };

      return gradingMap[this.user.role] || [];
    },

    paginationPaginate(){
      return String(this.paginate)
    },

    itemsPerPage(){
      return Number(this.paginate)
    }
  },
  created(){
    this.getInstructorGradingMutation([])
    this.getAdminGradingMutation([])
  },
  mounted(){
    this.getInstructorGradingMutation([])
    this.getAdminGradingMutation([])
    
    this.minDate = this.filter.created_from
    this.maxDate = this.filter.created_to
  },
  data: () => ({
    grading_tbl,
    loading: false,
    totalCount: 0,
    pageCount: 1,
    minDate: '',
    maxDate: '',
    status: [
      { text: 'ALL', value: '' },
      { text: 'PUBLISHED', value: 'PUBLISH' },
      { text: 'UNPUBLISHED', value: 'UNPUBLISH' },
    ],
    itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
  }),

};
</script>

<style></style>

